import { FormViewerLean, type FormValues } from '@wix/form-viewer/lean';
import React, { useEffect, useRef, useState } from 'react';
import { type FormViewerWithAIProps } from './interfaces';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import AiIcon from '../../../../../assets/images/Glyph.svg';
import classNames from 'classnames';
import { usePrevious } from '../../hooks/use-previous';
import {
  AssistantBiAction,
  useAssistantBi,
} from '../../hooks/use-assistant-bi';
import { getChangedValueTargetsForBi } from './FormViewerWithAi.utils';
import { READONLY_FIELD_TYPES } from '@wix/form-viewer';
import loadable from '@wix/yoshi-flow-editor/loadable';

import { classes } from './FormViewerWithAi.st.css';

const FormAssistant = loadable(
  () =>
    import(
      /* webpackChunkName: "form-app-form-viewer-ai-assistant" */ '../FormAssistant/FormAssistant'
    ),
);

export const FORM_VIEWER_WITH_AI_HOOKS = {
  container: 'FormViewerWithAIContainer',
  openAssistantButtonWrapper: 'FormViewerWithAIOpenAssistantButtonWrapper',
  assistantButton: 'AssistantButton',
  assistantChat: 'AssistantChat',
};

function isFormDataEqual(formData1?: FormValues, formData2?: FormValues) {
  return JSON.stringify(formData1) === JSON.stringify(formData2);
}

export const FormViewerWithAI = (props: FormViewerWithAIProps) => {
  const { aiAssistantProps, formViewerProps } = props;
  const { aiAssistantService, aiAssistantState } = aiAssistantProps;
  const { onChange, values, bi } = formViewerProps;
  const viewerWithAi = useRef<HTMLDivElement>(null);

  const { reportAction, reportAiFieldEdit, formFillingSessionId } =
    useAssistantBi({
      bi,
      formId: formViewerProps.formId,
    });

  const [aiAssistantOpen, setAiAssistantOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const prevAssistantFormData = usePrevious(aiAssistantState?.formData);

  useEffect(() => {
    if (
      aiAssistantState?.formData &&
      values &&
      !isFormDataEqual(prevAssistantFormData, aiAssistantState?.formData) &&
      !isFormDataEqual(aiAssistantState?.formData, values)
    ) {
      onChange(aiAssistantState?.formData);
      const changedValueTargets = getChangedValueTargetsForBi(
        aiAssistantState?.formData,
        values,
      );
      changedValueTargets.length && reportAiFieldEdit(changedValueTargets);
    }
  }, [aiAssistantState?.formData]);

  const openAiAssistant = () => {
    reportAction(AssistantBiAction.open);
    setAiAssistantOpen(true);
    aiAssistantService.initThread(values);
  };

  const closeChat = () => {
    reportAction(AssistantBiAction.close);
    setAiAssistantOpen(false);
  };

  const submitForm = async () => {
    if (typeof formViewerProps.ref === 'object') {
      const formValid = await formViewerProps.ref?.current?.validate();

      const submitButtonContainer = viewerWithAi?.current?.querySelector(
        `[data-field-type="${READONLY_FIELD_TYPES.SUBMIT_BUTTON}"]`,
      );
      const buttonElement = submitButtonContainer?.querySelector('button');
      buttonElement?.click();

      closeChat();

      if (formValid) {
        aiAssistantService.clearThread();
      }
    }
    return;
  };

  return (
    <div
      className={classes.container}
      ref={viewerWithAi}
      data-hook={FORM_VIEWER_WITH_AI_HOOKS.container}
    >
      {aiAssistantOpen && (
        <FormAssistant
          hostId={props.hostId}
          dataHook={FORM_VIEWER_WITH_AI_HOOKS.assistantChat}
          aiAssistantService={aiAssistantService}
          aiAssistantState={aiAssistantState}
          onSend={() => reportAction(AssistantBiAction.send)}
          submitForm={submitForm}
          formValues={values}
          isMobile={isMobile}
          onClose={closeChat}
        />
      )}
      <div>
        <div
          className={classNames(classes.buttonContainer)}
          data-hook={FORM_VIEWER_WITH_AI_HOOKS.openAssistantButtonWrapper}
        >
          <TextButton
            onClick={openAiAssistant}
            priority={TextButtonPriority.primary}
            data-hook={FORM_VIEWER_WITH_AI_HOOKS.assistantButton}
            prefixIcon={<AiIcon />}
          >
            {t('ai_assistant_button')}
          </TextButton>
        </div>
        <FormViewerLean
          {...formViewerProps}
          bi={bi}
          biFormFillSessionId={formFillingSessionId}
        />
      </div>
    </div>
  );
};

export default FormViewerWithAI;
